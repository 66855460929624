.search-button-container {
  top: 5px;
  left: 0;
  right: 0;
  padding: 10px;
}

.map-search-button {
  color: var(--black);
  padding: 7px 13px;
  border-radius: 50px;
  border: none;
  font-weight: 300;
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 54%);

  &:hover {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.758);
  }
}
