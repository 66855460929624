.toast-component {
  z-index: 80;
  position: relative;
  p {
    font-size: 15px;
    line-height: 22px;
  }
  .icon-close {
    margin-left: 25px;
  }
}
