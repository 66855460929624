.home {
  margin-top: -80px;
  &-header {
    min-height: 566px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 20px;

    @media (max-width: 750px) {
      margin-top: 35px;
      padding-bottom: 20px;
    }

    &-content {
      text-align: center;
      color: var(--white);
      padding: 0px 20px;

      h1 {
        font-size: 54px;
        line-height: 66px;
        margin-bottom: 20px;
      }

      @media (max-width: 881px) {
        margin-top: 40px;
      }

      @media (max-width: 545px) {
        h1 {
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }

  &-searchbar {
    max-width: 684px;
    margin: 0 auto;
    margin-top: 24px;

    .input-field {
      background-color: var(--white);
      border-radius: 12px;
      box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.12);

      .input-component {
        font-weight: 200;
        border: none;
      }
    }
  }

  &-providers-list {
    padding: 96px 90px 0px 90px;
    max-width: 1425px;
    margin: 0 auto;

    @media (max-width: 600px) {
      margin-right: 0px !important;
      margin-left: 0px !important;

      h2 {
        font-size: 26px !important;
      }
    }

    h2 {
      font-size: 32px;
      padding: 0px 10px;
    }

    &-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 8px;
    }

    @media (max-width: 1010px) {
      margin-top: 50px;
      padding: 20px;

      &-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 8px;
      }
    }
  }

  .blog-preview {
    padding: 90px;
    max-width: 1425px;
    margin: 0 auto;

    @media (max-width: 1010px) {
      padding: 90px 20px !important;
    }

    &-header {
      padding: 0px 8px;

      @media (max-width: 450px) {
        flex-direction: column;
      }
    }
  }

  .area-search {
    padding: 90px;
    max-width: 1425px;
    margin: 0 auto;

    @media (max-width: 1225px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h2 {
        max-width: none !important;
        font-size: 60px !important;
        line-height: 78px !important;
      }

      div {
        margin-top: 20px;
        width: 100%;
        max-width: 500px;

        div {
          margin-left: 0px;
        }
      }
    }

    @media (max-width: 570px) {
      padding: 14px;
      h2 {
        font-size: 40px !important;
        line-height: 58px !important;
        text-align: center;
      }
      div {
        padding: 10px;
        div {
          margin: 10px;
        }
      }
      a {
        font-size: 14px;
      }
    }

    h2 {
      font-size: 72px;
      line-height: 93.6px;
      max-width: 572px;
    }
  }
}

.book-appointment-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 2px 8px 1px rgb(0 0 0 / 17%);
}
