.navbar {
  height: 72px;
  padding: 12px 32px;
  z-index: 2;
  box-shadow:
    0px 1px 1px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  font-weight: 200;

  &-logo {
    left: 32px;
    top: 17px;
  }

  &-desktop {
    right: 32px;
  }

  &--transparent {
    background: transparent;
    box-shadow: none;

    a {
      color: white !important;
      font-weight: 300;
    }

    .button:not(.search-input-submit-button) {
      border-color: white !important;
    }
  }

  &-avatar {
    padding: 4px;
  }

  a {
    color: var(--black);
  }

  .daycare {
    left: 200px;
    background: none;
    border: none;
    font-weight: 300;

    .coming-soon {
      font-size: 8px;
      padding: 4px 6px 3px 6px;
      border-radius: 50px;
    }
  }

  &-searchbar {
    .input-field {
      background: var(--white);
      min-width: 448px;
      .input-component {
        border: none !important;
      }
    }
  }
  .searchbar-transparent {
    .input-field {
      background: transparent;
      color: var(--white);

      input {
        color: var(--white);
      }
      input::placeholder {
        color: var(--white);
      }
    }
  }

  .button:not(.search-input-submit-button) {
    font-weight: bold;
    padding: 12px 24px;
    border: 1px solid var(--black);
    color: var(--black);
    border-radius: 100px;
    background: transparent;
    margin-right: 0px !important;
  }

  .icon {
    cursor: pointer;
  }
}

.mobile-burger {
  z-index: 99999999;
  position: absolute;
  right: 37px;

  &--open {
    transition: all ease-in-out 200ms;
  }

  &__container {
    position: fixed;
    background: var(--primary-blue);
    height: 100%;
    left: 0px;
    top: 70px;
    width: 100%;
    z-index: 99;
    padding: 40px;
    transform: translateX(200%);
    transition: transform ease-in-out 200ms;
    overflow: auto;

    &-items {
      flex-direction: column;
      align-items: baseline;
      display: flex;
      height: 100%;

      a,
      button,
      .button {
        color: rgb(255, 255, 255) !important;
        font-size: 20px;
        margin-bottom: 25px;
        -webkit-tap-highlight-color: transparent;
        text-align: left;
        border-left: none !important;
        background: transparent;
        border: none;
        padding: 0px;
        font-weight: bold;
      }
    }

    &--open {
      transform: translateX(0);
    }
  }

  &--absolute {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 14px 16px;
    padding-top: 20px;
    position: absolute;
    box-shadow:
      0px 2px 3px 0px rgba(0, 0, 0, 0.16),
      0px 0px 1px 0px rgba(0, 0, 0, 0.12);
    background: var(--white);
  }

  .navbar__items {
    margin-top: 0px;
    a,
    p {
      font-weight: bold;
    }
  }

  .mini-navbar-items {
    flex-direction: column;
    align-items: flex-start;

    button,
    a {
      border: none;
      font-weight: bold;
      margin: 0px 0px 25px 0px;
    }
  }
}
