.create-pet {
  &-mobile {
    height: calc(100vh - 138px);
    .icon-arrowLeft {
      padding: 20px 20px 0px 20px;
    }
    &-title {
      padding: 20px 20px 0px 20px;
      font-size: 22px;
      margin-bottom: 12px !important;
    }

    &-form {
      padding-bottom: 0px;
      &-container {
        padding: 0px 20px 20px 20px;
      }
    }

    &-button-container {
      position: sticky;
      width: 100%;
      bottom: 0px;
      padding: 16px;
      border-top: 1px solid var(--light-purple);
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .checkbox__container {
      margin-top: 16px;
      margin-bottom: 0px;
      display: flex;
    }

    &__checkbox {
      flex-grow: 1;
      max-width: 240px;
      width: 100%;
    }
  }

  .vaccination-form-container {
    h4.type-title {
      width: 64.6%;
    }

    .vaccination-select {
      width: 66.6%;
      max-width: 64.6%;
    }

    .expiration-date-input {
      width: 33.3%;

      div.MuiInputBase-root {
        background: #f4f4fa;
      }
    }

    .dropdown-content div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }

    .add-vaccine-button {
      padding: 17px 24px;
    }
  }
}
