.home-loader {
  display: flex;
  flex-wrap: wrap;
  span {
    background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
    background-size: 400% 400%;
    animation: GradientAnimation 2s ease infinite;
  }

  @media (max-width: 1010px) {
    margin-top: 50px;
    padding: 20px;
    flex-direction: column;
  }

  &-card {
    max-width: 400px;
    margin: 0px 5px 24px 10px;
    padding: 32px 26px;
    border-radius: 12px;
    border: 1px solid var(--light-purple);
    background: var(--white);
    overflow-y: scroll;
    flex: 1 1 45%;
    max-width: 50%;

    @media (max-width: 1010px) {
      height: fit-content;
      padding: 16px;
      max-width: 100%;
    }

    &-header {
      &-avatar {
        width: 160px;
        height: 160px;
        border-radius: 92px;
      }
    }

    &-title {
      margin-left: 5px;
      width: 250px;
      height: 15px;
      margin-top: 30px;
    }

    &-address {
      margin-left: 5px;
      width: 100px;
      height: 15px;
      margin-top: 10px;
    }

    &-slots {
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(161px, 1fr));
      gap: 8px;
      &-slot {
        height: 55px;
        border-radius: 12px;
        min-width: 162px;
        padding: 11.5px 15px;
      }
    }
  }
}

@keyframes GradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
