.article {
  border: 1px solid var(--light-purple);
  border-radius: 12px;
  overflow: hidden;

  @media (max-width: 950px) {
    flex-direction: column;

    &-img {
      width: 100% !important;
      height: 200px !important;
    }
  }
  &-img {
    min-width: 240px;
    height: 240px;
    background-size: cover;
    background-position: center;
  }
  &-content {
    padding: 20px 56px;
    width: 98%;
    font-size: 20px;

    @media (max-width: 950px) {
      padding: 15px 30px;
    }

    &-summary {
      font-size: 16px;
      display: -webkit-box;
      width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--black);
    }

    a {
      font-size: 16px;
    }

    h2 {
      color: var(--black);
      font-size: 24px !important;
      line-height: 32px;
      display: -webkit-box;
      width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
