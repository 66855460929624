.pet-profile {
  align-items: flex-start;
  @media (max-width: 900px) {
    flex-direction: column;

    .info-block {
      max-width: 100%;
      display: inline-table;
      border-bottom: 8px solid var(--extra-light-purple);
    }
    .page-container {
      min-width: auto;
    }
  }

  &-container {
    box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.16);
    padding-bottom: 0px;

    @media (max-width: 600px) {
      height: fit-content;
    }

    .book-appointment-button-container {
      max-width: 666px;
    }
  }

  .mobile-actions-section {
    border-top: 8px solid var(--extra-light-purple);
    padding: 30px;
  }

  &-empty-state {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    max-width: 450px;
    margin: 0 auto;
  }

  .pet-information {
    height: 100%;
    padding: 48px 96px 20px 50px;
    max-width: 434px;

    @media (max-width: 900px) {
      padding: 10px 20px 20px 60px;
      flex-direction: row;
      justify-content: space-between;
      max-width: 450px;

      > div {
        margin-left: 50px;
      }

      .email {
        margin-bottom: 0px !important;
      }
    }

    @media (max-width: 600px) {
      padding: 20px;
      margin: 0 auto;

      > div {
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      .email {
        margin-bottom: 0px !important;
      }
    }
    h2 {
      font-size: 26px;
    }
    .avatar {
      width: 96px;
      height: 96px;
      background: '#FF9838';
      border-radius: 50%;
      margin-bottom: 16px;
    }
  }
}

.qr-code-section {
  background: #fef6ea;
  border-radius: 24px 24px 0px 0px;
  padding: 24px;
  .qr-code-app {
    width: 96px;
  }
}

.booking-modal {
  max-width: 700px;
}

.confirmation-modal {
  .service {
    margin-top: 16px;
    padding: 16px;
    border: 1px solid var(--light-purple);
    border-radius: 12px;

    .description {
      white-space: nowrap;
    }

    &:hover,
    &--active {
      color: var(--primary-orange);
      border-color: var(--primary-orange);

      p {
        color: var(--primary-orange);
      }
    }
  }

  .service-confirm {
    margin-top: 16px;
    margin-bottom: 15px;
    padding: 16px;
    border: 1px solid var(--light-purple);
    background: #f4f4fa;
    border-radius: 12px;
  }
}

.edit-pet-modal {
  h4.type-title {
    width: 64.6%;
  }

  .vaccination-select {
    width: 66.6%;
    max-width: 64.6%;
  }

  .expiration-date-input {
    width: 33.3%;

    div.MuiInputBase-root {
      background: #f4f4fa;
    }
  }

  .dropdown-content div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  .add-vaccine-button {
    padding: 17px 24px;
  }
}
