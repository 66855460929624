.blog-preview {
  padding: 96px 10px;
  max-width: 960px;
  margin: 0 auto;

  @media (max-width: 750px) {
    padding: 20px 20px 0px 20px;
  }

  h2 {
    font-size: 32px;
  }

  &-container {
    &-cta {
      border-radius: 12px;
      background: rgba(0, 179, 131, 0.1);
      padding: 32px;
      margin: 96px 0px;
      color: var(--black);
      padding-left: 56px;

      @media (max-width: 1180px) {
        flex-direction: column;
        text-align: center;
        padding-left: 32px;

        * {
          width: 100%;
          min-width: auto !important;
        }

        h2 {
          margin: 0px 0px 20px 0px;
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      h2 {
        font-size: 36px;
        line-height: 48px;

        @media (max-width: 1300px) {
          font-size: 48px;
          line-height: 67px;
          margin-right: 0px;
        }
      }

      div {
        min-width: 377px;
        height: 312px;
        border-radius: 5px;
        background-size: cover;
        background-position: center;
      }
    }
  }
  &-second-container {
    @media (max-width: 950px) {
      flex-direction: column;
    }
    .article {
      flex-direction: column;
      margin: 0px 8px;
      max-width: 400px;
      width: 100%;
      min-height: 461px;

      @media (max-width: 950px) {
        height: auto;
        max-width: fit-content;
        margin-bottom: 25px;
        min-height: auto;
      }

      div {
        width: 100%;
      }

      &-content {
        padding: 15px 30px;

        &-summary {
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}
