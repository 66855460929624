.switch {
  position: relative;
  display: inline-flex;
  height: 24px;
  width: 46px;
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid transparent;
  background-color: var(--light-purple);
  transition: all 0.2s ease-in-out;

  &--enabled {
    background-color: var(--white);
  }

  span {
    transform: translateX(-4px);
    pointer-events: none;
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: var(--primary-purple);
    transition: all 0.2s ease-in-out;
  }

  .enabled {
    transform: translateX(16px);
    background-color: var(--primary-green);
  }
}
