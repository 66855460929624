.footer {
  background-color: #fff5eb;
  padding: 96px;
  align-items: baseline;

  @media (max-width: 1085px) {
    flex-direction: column;
    padding: 40px;
  }

  &-left {
    max-width: 600px;
  }

  &-list {
    list-style-type: none;
    font-weight: 300;
    padding-left: 0px;

    @media (max-width: 400px) {
      margin: 10px;
      display: flex;
      justify-content: space-between;
    }

    li {
      margin-bottom: 12px;
    }
  }

  a {
    color: var(--black);
  }

  p {
    font-size: 18px;
    font-weight: 100;
    line-height: 30px;
  }

  .icon {
    margin-bottom: 13px;
  }

  .actions {
    margin-top: 13px;
  }
}
