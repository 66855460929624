.landing {
  display: flex;
  width: 100%;
  z-index: 0;
  @media (max-width: 960px) {
    flex-direction: column;
    overflow: scroll;
  }

  &-container {
    &-centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 40px;
      width: 100%;
      max-width: 508px;

      @media (max-width: 960px) {
        position: relative;
        transform: none;
        top: auto;
        left: auto;
      }

      &-block {
        width: fit-content;
        margin: 0 auto;
      }
    }

    h4 {
      margin-bottom: 10px;
      font-size: 24px;
    }
  }

  &-logout {
    bottom: 20px;
    left: 20px;
  }

  .l-left {
    background-size: cover;
    background-position: center;

    .l-title {
      font-size: 32px;
      line-height: 33px;
      margin-bottom: 9px;
    }

    .landing-description {
      font-size: 20px;
    }
  }
}
