.MuiDrawer-paper {
  margin-top: 0px !important;
  border-radius: 0px !important;
  padding: 32px !important;
  max-width: 500px;
  width: 100%;

  @media (max-width: 600px) {
    width: 100%;
    padding: 32px 15px !important;

    .close-drawer-icon {
      margin-top: 70px;
    }
  }
}
