.l-centered {
  h1 {
    font-size: 26px;
  }

  .input {
    margin-top: 32px;
  }
}

.login {
  .country-code-dropdown {
    min-width: 100px;
    max-width: 100px;
  }
}

.phone-container {
  .country-code-input {
    margin-right: 10px;
    min-width: 115px;
    max-width: 115px;

    .input-field {
      padding: 0px;
      width: 64px;
    }

    .dropdown-menu {
      min-width: 226px;
    }
  }
}

.admin-password-error {
  color: #d93232;
}
