.info-block {
  max-width: 620px;
  width: 100%;
  min-width: 250px;
  background: var(--white);
  height: 100%;
  padding: 96px 96px 20px 60px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 900px) {
    max-width: 100%;
    height: fit-content;
    overflow: initial;
    padding: 0px;

    &--custom {
      padding: 50px 0px 0px 0px;
    }
  }

  @media (max-width: 600px) {
    padding: 0px;
  }

  &--custom {
    padding: 0px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
