.user-search {
  min-height: 450px;
  max-height: 450px;
  overflow: scroll;
  &-box {
    max-width: 520px;
    width: 100%;
  }

  .admin-searchbar {
    .input-component {
      border: none;
    }
  }

  .providers-list {
    max-width: 520px;
    width: 100%;
    overflow: scroll;
    margin-top: 20px;

    .provider {
      border: 1px solid var(--light-purple);
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 20px;

      &:hover {
        background: #f4f4f9b2;
      }
    }
  }
}
