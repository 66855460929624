.mfa {
  height: 100%;

  form {
    @media (max-width: 600px) {
      height: calc(100% - 185px);
    }
  }
  .mfa-code {
    max-width: 568px;
    .input {
      margin-top: 0px !important;
      margin-right: 5px;

      .input-component {
        text-align: center;
      }
    }

    .separator {
      width: 68px;
      padding-right: 7px;
      padding-left: 5px;
    }
  }
  .input-field {
    width: 64px;

    @media (max-width: 480px) {
      width: 42px;

      .input-component {
        padding: 7px;
      }
    }
  }

  .right {
    right: 0;
    background: var(--white);

    .centered {
      max-width: 568px;
    }

    .mfa {
      .input {
        &:nth-of-type(1) {
          padding-left: 0px;
        }
        padding: 5px;

        .input-field {
          padding: 0px;
          max-width: 64px;

          .input-component {
            text-align: center;
          }
        }
      }

      input {
        &::placeholder {
          text-align: center;
        }
      }
    }

    button {
      margin-top: 36px;
    }
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 537px;
    width: 100%;
    padding: 40px;

    h1 {
      font-size: 26px;
    }

    h4 {
      margin-bottom: 10px;
      font-size: 24px;
    }
  }
}
