.settings {
  min-width: 480px;

  @media (max-width: 480px) {
    min-width: auto;
    width: 100%;
  }

  &-title {
    line-height: 28px;
  }

  &-wrapper {
    max-width: 490px;

    @media (max-width: 600px) {
      border: none;
    }
  }

  &-button {
    &:hover {
      text-decoration: underline;
    }
  }

  .settings-section {
    border-bottom: 1px solid var(--light-purple);
    padding: 12px 24px;

    @media (max-width: 600px) {
      padding: 12px 0px;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }

    .settings-section-header {
      .float-right {
        margin-left: auto;
        margin-right: 0px;
      }
    }
  }
  .left {
    float: left;
  }

  .breadcrumb-separator {
    margin: 0 5px;
  }

  .no-border-bottom {
    border-bottom: none !important;
    padding-bottom: 0px;
  }

  .icon-lock {
    margin-right: 31px;
  }
}
