.reschedule-appointment-modal-content {
  &-actions {
    max-width: 335px;

    @media (max-width: 400px) {
      flex-direction: column;

      > button {
        &:last-of-type {
          margin-top: 15px;
        }
      }
    }
  }
}
