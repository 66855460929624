:root {
  --white: #fff;
  --black: #000;
  --extra-light-orange: #fff9f6;
  --light-orange: #fff5eb;
  --primary-orange: #ff9838;
  --secondary-orange: #d07104;
  --primary-green: #00b383;
  --secondary-green: #0c8363;
  --light-green: #e6f7f3;
  --primary-blue: #150828;
  --primary-purple: #63637b;
  --secondary-purple: #9191a8;
  --light-purple: #cacad9;
  --extra-light-purple: #f1f1f6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-wrapper-container {
  height: 100vh;
  display: flex;
}

.app-wrapper-container-page {
  margin-top: 72px;
  width: 100%;
  display: flex;
}

.app-wrapper-container-page--with-banner {
  margin-top: 124px;
}

@media (max-width: 910px) {
  .app-wrapper-container-page--booking-flow {
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .app-wrapper-container {
    min-height: -webkit-fill-available;
    /* height: 100%; */
    -webkit-overflow-scrolling: touch;
  }
}

* {
  outline: none;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
  box-sizing: border-box;
}

textarea {
  padding-top: 20px;
  min-height: 191px;
}

.textarea .input-field {
  padding-right: 0px !important;
}

input[type='text']::placeholder {
  font-weight: 200;
  color: rgb(99, 99, 123);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f4f4fa inset !important;
}

/* Hide the default clear button */
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

[placeholder] {
  text-overflow: ellipsis;
}

::-webkit-scrollbar {
  display: none;
}

h1 {
  margin: 0px;
}

p {
  font-weight: 100;
}

p,
h2,
h3,
h4,
h5 {
  margin: 0px;
}

a {
  color: var(--primary-orange);
  text-decoration: none;
}
a:hover {
  color: var(--secondary-orange);
}

/* Display */

.inline-display {
  display: inline !important;
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

@media (max-width: 639px) {
  .mobile-flex-row {
    flex-direction: row;
  }
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch !important;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.pos-fixed {
  position: fixed;
}

.grid {
  display: grid;
}

.grid-cols-minmax-maxcontent {
  grid-template-columns: minmax(0, 1fr) max-content;
}

.grid-cols-autofill-minmax-150-1fr {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

/* Width and Height */

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-fit {
  width: fit-content;
}

.mw-190 {
  max-width: 190px;
}

.mw-420 {
  max-width: 420px;
}

.mw-450 {
  max-width: 450px !important;
}

.max-w-322 {
  max-width: 322px;
}

.max-w-420 {
  max-width: 420px;
}

.max-w-480 {
  max-width: 480px;
}

.max-w-510 {
  max-width: 510px;
}

.max-w-600 {
  max-width: 600px;
}

.max-w-620 {
  max-width: 620px;
}

.max-w-660 {
  max-width: 660px;
}

.max-w-768 {
  max-width: 768px;
}

.min-w-0 {
  min-width: 0;
}

.min-w-380 {
  min-width: 380px;
}

.min-w-386 {
  min-width: 386px;
}

.h-1 {
  height: 1px;
}

.h-8 {
  height: 8px;
}

.h-250 {
  height: 250px;
}

.h-100 {
  height: 100%;
}

/* Colors */

.b-transparent {
  background-color: transparent;
}

.b-white {
  background: var(--white);
}

.b-green {
  background-color: var(--primary-green);
}

.b-light-grey {
  background-color: var(--light-purple);
}

.b-extra-light-grey {
  background-color: var(--extra-light-purple);
}

.b-blue {
  background-color: var(--primary-blue);
}

.b-light-orange {
  background: var(--extra-light-orange);
}

.text-white {
  color: var(--white);
}

.text-orange {
  color: var(--primary-orange);
}

.text-orange:hover {
  color: var(--secondary-orange);
}

.text-green {
  color: var(--primary-green);
}

.text-green:hover {
  color: var(--secondary-green);
}

.text-black {
  color: var(--black);
}

.text-primary-purple {
  color: var(--primary-purple);
}

.text-red {
  color: #de3730;
}

.text-grey-dark {
  color: #63637b;
}

.text-grey {
  color: #63637b;
}

.text-light-grey {
  color: #b7b5bd;
}
.border-none {
  border: none;
}

.border-light-purple {
  border: 1px solid var(--light-purple);
}

.border-top-light-purple {
  border-top: 1px solid var(--light-purple);
}

.border-primary-purple {
  border: 1px solid var(--primary-purple);
}

.border-white {
  border: 1px solid var(--white);
}

.border-primary-orange {
  border: 1px solid var(--primary-orange);
}

.border-bottom-grey {
  border-bottom: 1px solid #cacad9;
}

.border-2 {
  border-width: 2px;
}

/* Text */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

/* Spacing */

.margin-center {
  margin: 0 auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-3 {
  margin-left: 3px;
}

.mt-4 {
  margin-top: 4px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-12 {
  margin-top: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mr-25 {
  margin-right: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mt-42 {
  margin-top: 42px !important;
}

.mb-75 {
  margin-bottom: 75px;
}

.mr-96 {
  margin-right: 96px;
}

.mt-50 {
  margin-top: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mt-n40 {
  margin-top: -40px;
}

.plr-5 {
  padding: 0px 5px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-48 {
  padding-left: 48px;
}

.p-0 {
  padding: 0px !important;
}

.p-10 {
  padding: 10px;
}

.pt-12 {
  padding-top: 12px;
}

.pl-12 {
  padding-left: 12px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.p-15 {
  padding: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.p-16 {
  padding: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.p-20 {
  padding: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-24 {
  padding: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.pl-32 {
  padding-left: 32px;
}

.pt-32 {
  padding-top: 32px;
}

.pb-40 {
  padding-bottom: 40px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pb-30 {
  padding-bottom: 30px;
}

.p-32 {
  padding: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.p-50 {
  padding: 50px;
}

.pt-72 {
  padding-top: 72px;
}

.px-72 {
  padding-left: 72px;
  padding-right: 72px;
}

.pt-96 {
  padding-top: 96px;
}

.pr-96 {
  padding-right: 96px;
}

@media (max-width: 600px) {
  .pb-100-mobile {
    padding-bottom: 100px !important;
  }
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-22 {
  gap: 22px;
}

.gap-32 {
  gap: 32px;
}

.gap-56 {
  gap: 56px;
}

.gap-y-8 {
  row-gap: 8px;
}

.gap-y-32 {
  row-gap: 32px;
}

.gap-x-12 {
  column-gap: 12px;
}

.gap-x-16 {
  column-gap: 16px;
}

/* Font size */

.f32 {
  font-size: 32px;
}

.f24 {
  font-size: 24px;
}

.f26 {
  font-size: 26px;
}

.f22 {
  font-size: 22px;
}

.f20 {
  font-size: 20px;
}

.f18 {
  font-size: 18px;
}

.f17 {
  font-size: 17px;
}

.f16 {
  font-size: 16px;
}

.f15 {
  font-size: 15px;
}

.f14 {
  font-size: 14px;
}

.f13 {
  font-size: 13px;
}

.f12 {
  font-size: 12px;
}

.f11 {
  font-size: 11px;
}

.f10 {
  font-size: 10px;
}

.fw-100 {
  font-weight: 100;
}

.fw-400 {
  font-weight: 400 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

/* Other */

.pointer {
  cursor: pointer;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.overflow-inherit {
  overflow-y: inherit !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.no-style-btn {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.no-style-ul {
  list-style-type: none;
  padding: 0;
}

.radius-8 {
  border-radius: 8px;
}

.radius-10 {
  border-radius: 10px;
}

.radius-12 {
  border-radius: 12px;
}

.radius-16 {
  border-radius: 16px;
}

.radius-20 {
  border-radius: 20px;
}

.radius-24 {
  border-radius: 24px;
}

.radius-50 {
  border-radius: 50px;
}

.radius-100 {
  border-radius: 100px;
}

.elevation-8 {
  box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.12);
}

.background-position-50 {
  background-position: 50%;
}

.background-cover {
  background-size: cover;
}

/* Fonts */

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins-Regular'),
    url(./fonts/Poppins-Regular.woff) format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins-Bold'),
    url(./fonts/Poppins-Bold.woff) format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins-ExtraBold'),
    url(./fonts/Poppins-ExtraBold.woff) format('woff');
  font-weight: bolder;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins-Black'),
    url(./fonts/Poppins-Black.woff) format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins-Medium'),
    url(./fonts/Poppins-Medium.woff) format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins-Italic'),
    url(./fonts/Poppins-Italic.woff) format('woff');
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins-Light'),
    url(./fonts/Poppins-Light.woff) format('woff');
}

/* General */

.toast {
  background: #414155 !important;
  color: #fff !important;
  border-radius: 11px !important;
  max-width: 410px;
}

.label {
  font-weight: bold;
  font-size: 18px;
}
.page-container {
  padding: 48px 72px;
  width: 100%;
  height: 100%;
  min-width: 500px;
  overflow: scroll;
}
.page-container h1 {
  font-size: 26px;
}

@media (max-width: 900px) {
  .page-container {
    box-shadow: none !important;
    padding: 30px;
    overflow: initial;
    min-width: auto;
  }
}

.z-index-3 {
  z-index: 3;
}

/* Landing */

.split-screen {
  display: flex;
  width: 100%;
}

.split-screen .split {
  width: 50%;
  display: flex;
  justify-content: center;
}

.split-screen .centered {
  width: 100%;
  padding: 56px;
  position: relative;
}

@media (max-width: 850px) {
  .split-screen {
    flex-direction: column;
  }
  .split-screen .split {
    width: 100%;
  }
  .split-screen .centered {
    padding: 20px;
  }
}

.l-split {
  height: 100%;
  width: 50%;
  z-index: 1;
  top: 0;
}

@media (max-width: 950px) {
  .l-split {
    height: auto;
  }
}

.l-left {
  left: 0;
  background: var(--primary-blue);
  color: white;
  position: relative;
}

.l-right {
  right: 0;
  background: white;
  overflow: scroll;
  padding-bottom: 50px;
}

.l-centered {
  padding: 40px 94px;
}

@media (max-width: 1250px) {
  .l-left {
    width: 40%;
  }

  .l-right {
    width: 60%;
  }
}

@media (max-width: 1050px) {
  .l-centered {
    padding: 40px;
    margin-top: 0px;
  }
}

@media (max-width: 960px) {
  .l-left {
    width: 100%;
  }

  .l-right {
    width: 100%;
    overflow: initial;
  }
}

.l-centered h1 {
  margin-bottom: 32px;
}

@media (max-width: 520px) {
  .responsive {
    flex-direction: column;
  }

  .responsive > * {
    width: 100%;
    margin: 10px 0px 10px 0px;
  }
}

/* Modals */

body.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background-color: #1508289c !important;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Content {
  max-height: 90vh;
  min-width: 200px;
  max-width: 680px;
  width: 90%;
  border-radius: 20px !important;
  animation: fadein 0.2s;
  transition: all 0.2s ease;
  inset: auto !important;
  padding: 36px !important;
  background-color: white !important;
  overflow-y: scroll;
}

@media (max-width: 500px) {
  .ReactModal__Content {
    padding: 20px 18px !important;
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0px !important;
  }
}

@keyframes fadein {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

.ReactModal__Content--after-open {
  transform: scale(1);
}

.app-wrapper--disable-scroll {
  overflow: hidden !important;
}

.MuiModal-root {
  z-index: 9999 !important;
}

.MuiPickersPopper-root {
  z-index: 99999 !important;
}

.create-animal-modal,
.cancel-appointment-modal,
.reschedule-appointment-modal {
  max-width: 460px;
}

.user-not-found-modal {
  max-width: 542px;
}

.daycare-modal {
  max-width: 560px;
}

/* Typeahead */

.rbt-input-main {
  width: 100% !important;
  background: none;
  border: none;
  font-size: 18px;
  padding: 17px 20px;
  border-radius: 12px;
  font-weight: 100;
  border: 1px solid var(--light-purple);
  background: #f4f4fa;
  min-height: 64px;
  border-radius: 12px;
  background: #f4f4fa;
}

.rbt.white .rbt-input-main {
  background: var(--white);
}

@media (max-width: 600px) {
  .rbt-input-main {
    min-height: 52px !important;
    height: 52px;
  }
}

.rbt-menu.dropdown-menu.show {
  z-index: 2;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: var(--white);
  border-radius: 12px !important;
  border: 1px solid #f1f1f1;
  padding: 5px 0px;
  margin-top: 5px;
}

.dropdown-item {
  float: left;
  width: 100%;
  color: #1e2125 !important;
  padding-left: 24px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fffaf5 !important;
  color: #1e2125 !important;
}

.dropdown-item:hover {
  background-color: #fffaf5 !important;
  color: #1e2125 !important;
}

.rbt-menu > .dropdown-item {
  padding: 12px 24px;
  font-weight: 200;
}
@media (max-width: 600px) {
  .mobile-page-title {
    padding: 20px 20px 0px 20px;
  }
}

.plus-icon {
  transform: rotate(-45deg);
}

.date-of-birth-input .MuiFormControl-root {
  width: 100%;
}
