.search-provider-card {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--light-purple);
  background: var(--white);
  margin-top: 13px;
  scroll-margin: 13px;

  &-slots {
    margin-top: 10px;
  }

  &-slot {
    max-width: 123px;
    min-width: auto;
    width: 100%;
  }

  &--active {
    animation: change-color 2s ease;
  }

  @media (max-width: 600px) {
    .slot-button {
      margin-left: 0px !important;
      min-width: auto;
      width: 100%;

      &--empty {
        max-width: 100% !important;
      }
    }
  }

  .view-more-custom {
    min-width: auto;
  }
}

@keyframes change-color {
  0% {
    background-color: #f7983812;
    border-color: var(--primary-orange);
  }
  90% {
    background-color: #f7983812;
    border-color: var(--primary-orange);
  }
  100% {
    background-color: initial;
    border: 1px solid var(--light-purple);
  }
}
