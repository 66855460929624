.progress-bar {
  max-width: 421px;

  @media (max-width: 600px) {
    margin-bottom: 0px !important;
  }

  &-section {
    &-line {
      flex: 1;
      height: 2px;
    }

    &-circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }
}
