.users-pets-bottom-modal {
  ul {
    padding-left: 0px;
    list-style-type: none;
    margin-top: 0px;
    li {
      border-bottom: 1px solid var(--light-purple);

      &:last-child {
        border-bottom: none;
      }

      .rbt-input-main {
        max-width: 24px;
        width: 24px !important;
        height: 24px;
      }
    }
  }
}
