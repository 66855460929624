.searchbar-transparent {
  .input-field {
    min-width: 330px;
    min-height: 50px;
    transition: all 0.25s 0ms ease;

    input.input-component {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0px;
    }

    button {
      display: none;
      position: relative;
      right: 12px;
      height: 34px;
    }
  }

  &:focus-within div.input-field {
    background-color: var(--white);
    min-width: 420px;
    padding-left: 12px !important;

    svg path {
      fill: grey;
    }

    input.input-component {
      color: var(--black);

      &::placeholder {
        font-weight: 200;
        color: rgb(99, 99, 123);
      }
    }

    button {
      display: flex;
    }
  }
}

.mobile-searchbar div.input--small .input-field-container input {
  border: 0;
}
