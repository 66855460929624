.advanced-search {
  max-width: 945px;
  gap: 24px;
  box-shadow: 0 16px 20px rgba(0, 0, 0, 0.12);

  .pet-filter {
    min-width: 180px;
    max-width: 204px;
    flex-grow: 1;
  }

  .size-filter {
    min-width: 175px;
    max-width: 199px;
    flex-grow: 1;
  }

  .pet-select {
    min-width: 213px;
    max-width: 232px;
    flex-grow: 1;
  }

  .location-search {
    margin-top: 0;
    width: unset;
    min-width: 213px;
    flex-grow: 4;

    #location-search {
      height: 44px;
    }
  }

  .submit-button {
    min-width: 100px;
    max-width: 160px;
    height: 44px;
    font-size: 13px;
    flex-grow: 1;
  }

  @media (max-width: 828px) {
    gap: 12px;
    padding: 24px 12px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 24px;

    .pet-filter,
    .size-filter,
    .pet-select,
    .location-search,
    .submit-button {
      max-width: 100%;
      width: 100%;
    }
  }
}
