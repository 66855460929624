.stripe-widget {
  .cardElement-container {
    padding: 17px 20px;
    border-radius: 12px;
    border: 1px solid var(--light-purple);
    background: #f4f4f9;
    max-height: 58px;
  }

  &.white {
    .cardElement-container {
      background: var(--white);
    }
  }
}
