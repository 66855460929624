.search-loader {
  display: flex;
  flex-direction: column;

  span {
    background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
    background-size: 400% 400%;
    animation: GradientAnimation 2s ease infinite;
  }

  .row {
    width: 100%;
    height: 161px !important;
    margin-top: 12px;
    background-size: 100% 100%;
    border: 1px solid var(--light-purple);
    border-radius: 12px;
    overflow: hidden;

    .content {
      padding: 50px;

      span {
        border-radius: 2px;
        margin-bottom: 16px;
        background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
        background-size: 400% 400%;
        animation: GradientAnimation 2s ease infinite;
        width: 100%;
        height: 12px;
      }

      .cta {
        width: 80px;
        display: block;
        margin-top: 30px;
      }
    }

    &-avatar {
      width: 40%;
      height: 100%;
      margin-right: 15px;
    }
  }
}

@keyframes GradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
