.booking-flow-loader {
  div:not(.flex-column) {
    background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
    background-size: 400% 400%;
    animation: GradientAnimation 2s ease infinite;
  }

  div.title-loader {
    width: 420px;
    height: 39px;
    border-radius: 4px;
  }

  div.item-loader {
    width: 100%;
    height: 80px;
    border-radius: 12px;
  }

  div.button-loader {
    width: 155px;
    height: 60px;
    border-radius: 32px;
  }
}

@keyframes GradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
