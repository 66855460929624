.upload-avatar-page-mobile {
  height: 100%;
  justify-content: space-around;

  h1 {
    padding: 20px 20px 0px 20px;
  }

  form {
    height: 100%;

    .mobile-container {
      padding: 20px;
      height: 100%;
    }
  }
}
