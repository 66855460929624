.checkout-container {
  @media (max-width: 900px) {
    flex-direction: column;
  }
  .checkout {
    box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.16);

    > div {
      max-width: 650px;
      padding-right: 50px;
    }
    @media (max-width: 600px) {
      padding: 0px;
      //height: 100vh;

      > div {
        padding: 0px;
        height: 100%;
        justify-content: space-between;
        //height: 100%;
      }
    }
  }
}

.appointment-info {
  max-width: 322px;
  width: 100%;

  @media (max-width: 900px) {
    max-width: none;
    padding: 35px 20px 0px 20px;
    border-bottom: 5px solid var(--extra-light-purple);
  }

  &-service {
    text-align: left;

    &-price {
      padding-top: 15px;
      border-top: 1px solid var(--light-purple);
    }

    &-description {
      margin-top: 8px;
      display: -webkit-box;
      max-height: 150px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
