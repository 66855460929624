input[type='file'] {
  display: none;
}

.image-uploader {
  cursor: pointer;
  display: block;
  width: fit-content;
  position: relative;

  &--profile {
    background: #63637b;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &--medium {
    width: 96px;
    height: 96px;
    border-radius: 50px;

    .icon-pencil {
      margin-top: 72px;
      margin-left: 62px;
    }

    .change-text {
      font-weight: 100;
      font-size: 13px;
      bottom: -37px;
      width: max-content;
    }
  }

  &--big {
    width: 197px;
    height: 197px;
    border-radius: 100px;

    .icon-pencil {
      margin-top: 160px;
      margin-left: 125px;
    }

    .change-text {
      font-weight: bold;
      font-size: 17px;
      bottom: -37px;
    }
  }

  .icon-pencil {
    cursor: pointer;
    background: var(--white);
    padding: 7px;
    border-radius: 50px;
    box-shadow: 0px 2px 4px 0px #0101014f;
    position: absolute;
    z-index: 3;
  }
  /* Media query for mobile screens */
  @media screen and (max-width: 899px) {
    .icon-pencil {
      z-index: 2; /* Resets the z-index for mobile screens */
    }
  }
}

.image-uploaded {
  border-radius: 50%;
  background-size: cover !important;
  background-position: center !important;

  &--medium {
    width: 96px;
    height: 96px;
    margin-top: -96px;
  }

  &--big {
    width: 200px;
    height: 200px;
    margin-top: -200px;
  }

  &--default {
    width: 144px;
    height: 144px;
  }

  &--profile {
    z-index: 1;
    position: relative;
  }
}
