.pet-profile-list-item {
  max-width: 666px;

  &-container {
    color: var(--black);
    cursor: pointer;
    background: var(--white);
    padding: 16px;
    border: 1px solid var(--light-purple);
    border-radius: 12px;
    margin-bottom: 12px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    min-height: 76px;

    @media (max-width: 600px) {
      flex-direction: column;
      height: max-content;

      &-header {
        flex-direction: column;
        align-items: center !important;
      }

      &-info {
        margin-bottom: 15px;
        margin-top: 5px;
        * {
          text-align: center;
        }
      }
    }

    &-info {
      padding-right: 10px;
      text-align: left;
    }

    @media (max-width: 400px) {
      h4 {
        max-width: 100%;
      }
    }

    &-detail {
      @media (max-width: 600px) {
        padding-top: 5px;
      }
    }

    h2 {
      font-size: 15px;
    }

    h4 {
      font-size: 15px;
    }

    .note {
      font-size: 13px;
    }

    .status-label {
      padding: 4px 12px;
      border-radius: 50px;
      font-size: 11px;
      font-weight: bold;
      color: var(--white);
      background-color: var(--primary-purple);
      min-width: fit-content;

      // Completed/Paid
      &--2 {
        background-color: var(--light-green);
        color: var(--primary-green);
      }
    }
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    .dropdown-container {
      .dropdown {
        background: transparent;

        fieldset {
          border-color: transparent;
        }

        .MuiSelect-select {
          padding: 10px 32px 10px 9px !important;
        }
      }

      .icon {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.pet-profile-list-item-container--new {
  background: #f4f4fa;
}
