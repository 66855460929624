.search-page {
  @media (max-width: 1753px) {
    &-map {
      width: 60% !important;
    }
  }

  @media (max-width: 1432px) {
    &-map {
      width: 100% !important;
    }
  }

  @media (max-width: 1135px) {
    flex-direction: column-reverse;

    &-map {
      width: 100% !important;
      height: 200px !important;
      position: fixed;
      z-index: 1;
      top: 72px;
    }

    &-providers {
      width: 100% !important;
      padding-top: 237px !important;
      max-width: none !important;
      overflow: inherit !important;
    }
  }

  &-providers {
    padding: 32px;
    overflow: auto;
    height: 100%;
    max-width: 666px;

    .row {
      height: 219px;
    }
  }

  &-map {
    height: 100%;
    overflow: hidden;
    display: block;
    width: 76%;
  }

  .search-filters {
    @media (max-width: 400px) {
      flex-wrap: wrap;
      margin-bottom: 0px !important;

      > div {
        margin-bottom: 8px;
      }
    }
  }

  .mobile-provider-filter {
    border: 1px solid var(--light-purple);
    padding: 0px 7px 0px 16px;
    height: 36px;
    border-radius: 50px;

    &--checked {
      border-color: var(--primary-orange);
      color: var(--primary-orange);
      background: rgb(255, 213, 181);
      background: rgb(255 247 242 / 37%);
    }
  }

  &-date-filter {
    padding: 9.5px 16px;
    position: relative;
  }

  &-date-picker {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-left: -16px;
    border-radius: 100px;

    .MuiFormControl-root {
      height: 100%;
      width: 100%;
      background: transparent;
      position: absolute;
      .MuiInputBase-root {
        height: 100%;
        width: 100%;
        border-radius: 50px !important;
        overflow: hidden;
        padding-right: 0px !important;
        input {
          display: none;
        }
        .MuiInputAdornment-root {
          width: 100%;
          height: 100%;
          margin: 0;
          button {
            width: 100%;
            height: 100%;
            svg {
              display: none;
            }

            &:hover {
              background-color: transparent;
            }

            .MuiTouchRipple-root {
              display: none;
            }
          }
        }
      }
    }
  }

  .no-results {
    max-width: 343px;
  }
}

.mobile-provider-filter {
  &-item {
    font-size: 15px !important;
    padding: 5px 20px !important;
    color: var(--primary-purple);
    &:hover {
      background: transparent !important;
      color: var(--primary-orange) !important;
    }
  }
}

.mobile-provider-filter-item.Mui-selected {
  background-color: transparent !important;
  color: var(--primary-orange);
  &:after {
    content: '';
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='check'%3E%3Cpath id='Icon' d='M20 6L9 17L4 12' stroke='%23FF9838' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
    margin-left: 10px;
  }
}
