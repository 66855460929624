.rdp-root {
  width: 100%;
  border: 1px solid var(--light-purple);
  border-radius: 16px;
  padding: 24px 16px;
  display: flex;
  justify-content: center;
}

.rdp-months {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
}

@media (min-width: 640px) {
  .rdp-nav {
    display: none;
  }
}

.rdp-chevron {
  fill: var(--black);
}

.rdp-month {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rdp-month_caption {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.rdp-caption_label {
  font-size: 18px;
  font-weight: bold;
}

.rdp-month_grid {
  border-spacing: 12px;
  overflow: hidden;
  border-collapse: separate;
}

.rdp-weekday {
  font-size: 16px;
  font-weight: bold;
  width: 36px;
  height: 36px;
  padding: 6px;
}

.rdp-day {
  width: 34px;
  height: 34px;
  padding: 0;
  border: 1px solid transparent;
  color: var(--primary-blue);
}

.rdp-day_button {
  width: 34px;
  height: 34px;
  font-size: 13px;
  font-weight: 400;
}

.rdp-day:not(.rdp-disabled):hover {
  background-color: var(--extra-light-orange);
  border-radius: 24px;
}

.rdp-disabled .rdp-day_button {
  color: var(--primary-purple);
  font-weight: 400;
}

.rdp-selected:not(.rdp-range_middle) {
  border: 1px solid var(--primary-orange);
  background-color: var(--light-orange);
  border-radius: 24px;
}

.rdp-selected:not(.rdp-range_middle) .rdp-day_button {
  color: var(--primary-orange);
  font-weight: bold;
  background: transparent;
  border: none;
}

.rdp-range_start {
  position: relative;
  background: var(--light-orange);
}

.style-start-end-knobs .rdp-range_start::after {
  content: '';
  position: absolute;
  left: 50%;
  right: -7px;
  top: -1px;
  bottom: -1px;
  background: var(--light-orange);
  z-index: -1;
}

.rdp-range_start .rdp-day_button {
  color: var(--primary-orange);
}

.rdp-range_middle {
  position: relative;
  background: var(--light-orange);
  font-weight: 400;
}

.rdp-range_middle::after {
  content: '';
  position: absolute;
  left: -30px;
  right: -30px;
  top: -1px;
  bottom: -1px;
  background: var(--light-orange);
  z-index: -1;
}

.rdp-range_end {
  position: relative;
  background: var(--light-orange);
}

.style-start-end-knobs .rdp-range_end::after {
  content: '';
  position: absolute;
  left: -7px;
  right: 50%;
  top: -1px;
  bottom: -1px;
  background: var(--light-orange);
  z-index: -1;
}

.rdp-range_end .rdp-day_button {
  color: var(--primary-orange);
}

.rdp-today:not(.rdp-disabled) .rdp-day_button {
  color: var(--black);
  font-weight: bold;
}

.rdp-today.rdp-disabled .rdp-day_button {
  font-weight: 600;
}
