.about-page {
  &-container {
    padding: 96px 10px;
    max-width: 980px;
    margin: 0 auto;

    @media (max-width: 900px) {
      padding: 20px 20px 60px 20px;
    }

    h1,
    h2 {
      font-size: 40px;
      line-height: 56px;

      @media (max-width: 450px) {
        font-size: 38px;
        line-height: 53px;
      }
    }

    p {
      font-size: 20px;
      line-height: 30px;
      margin-top: 12px;
    }

    h1 {
      margin-top: 48px;
    }

    h2 {
      margin-top: 96px;
    }

    &-cards {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      &-card {
        border-radius: 12px;
        background: #e5f7f3;
        padding: 32px;
        height: 444px;
        width: 310px;
        margin: 0px 5px 12px 5px;

        @media (max-width: 900px) {
          width: 100%;
          height: auto;
          padding: 32px;
        }
        h3 {
          font-size: 72px;
          line-height: 100px;
        }

        h4 {
          font-size: 24px;
          padding-top: 30px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .team-section {
      margin-top: 20px;

      @media (max-width: 800px) {
        flex-direction: column;

        .kunal {
          margin-bottom: 20px;
          width: 100%;
          border-radius: 10px !important;
        }
      }

      .kunal {
        min-width: 300px;
        height: 300px;
        border-radius: 300px;
        background-size: cover;
        background-position: center;
      }

      &-content {
        font-size: 24px;
        font-style: italic;
        line-height: 36px;
        margin-left: 60px;
        @media (max-width: 800px) {
          margin: 0;
        }

        span {
          font-style: italic;
          margin-bottom: 24px;
          display: block;
        }
        p {
          font-size: 26px;
          line-height: 40px;
          margin-top: 0px;
          color: var(--primary-purple);
        }
      }
    }
  }

  .struck-section {
    text-align: center;
    margin-top: 23px;
    .struck-logo {
      margin: 0 auto;
      margin-top: 7px;
      max-width: 362px;
      width: 100%;
    }
  }
}
