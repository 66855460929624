.booking-loader {
  max-width: 945px;
  height: 92px;

  .loader-bg {
    background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
    background-size: 400% 400%;
    animation: GradientAnimation 2s ease infinite;
  }

  .avatar-loader {
    height: 54px;
    width: 54px;
    border-radius: 50%;
  }

  .title-loader {
    height: 24px;
    width: 416px;
    border-radius: 4px;
  }

  .button-loader {
    height: 47px;
    width: 138px;
    border-radius: 100px;
  }
}

@keyframes GradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
