.slot-loader {
  background: linear-gradient(270deg, #d8d8e4, #f4f4fa, #d8d8e4);
  background-size: 400% 400%;
  animation: GradientAnimation 2s ease infinite;

  height: 50px;
  width: 190px;
  border-radius: 50px;
  margin-top: 10px;
}

@keyframes GradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
