.rebook-banner {
  max-width: 945px;
  padding-top: 19px;
  padding-bottom: 19px;
  box-shadow: 0 16px 20px rgba(0, 0, 0, 0.12);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
  }
}
