.dropdown {
  height: 64px;
  border-radius: 12px;
  background: #f4f4fa;
  width: 100%;
  font-weight: 400 !important;
  max-height: 64px !important;
  text-align: left;
  cursor: pointer !important;

  &:hover {
    fieldset {
      border-color: var(--secondary-purple) !important;
    }
  }

  &-container {
    .Mui-focused {
      fieldset {
        border: 1px solid #f89232 !important;
        outline: none !important;
      }
    }
  }

  &--white {
    background-color: var(--white);
    p {
      color: var(--black);

      &.dropdown-placeholder {
        color: var(--primary-purple);
      }
    }
  }

  &--read-only {
    .dropdown-placeholder {
      color: grey;
    }
  }

  &--extra-small {
    height: 42px !important;
    padding: 0px;
    .MuiSelect-select {
      padding-left: 16px !important;
      font-size: 13px !important;

      @media (max-width: 600px) {
        font-size: 16px !important;
      }
    }
  }

  &--small {
    height: 44px !important;
    padding: 0px;
    .MuiSelect-select {
      padding: 0px 16px !important;
      font-size: 13px !important;

      @media (max-width: 600px) {
        font-size: 16px !important;
      }
    }
  }

  &--big {
    height: 64px !important;
    padding: 0px;
    .MuiSelect-select {
      padding: 0px 24px !important;
      font-size: 18px !important;
    }
  }

  &--square {
    border-radius: 12px !important;
  }

  &--round {
    border-radius: 100px !important;
  }

  &:focus {
    border: 1px solid var(--primary-orange);
  }

  &-placeholder {
    color: #757575;
  }

  &-placeholder,
  &-content {
    line-height: 32px;
    min-height: 10px;
  }

  &-menu {
    line-height: 32px;
    &--small {
      font-size: 11px;
      .dropdown-item {
        padding: 4px 19px;
      }
    }
  }
  &-disabled {
    pointer-events: none;
    color: grey;

    &:hover {
      background-color: white !important;
    }
  }
}

.dropdown-no-style {
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-right: 0px !important;
}

.disable-dropdown {
  pointer-events: none;
}

.clear-icon-container {
  position: absolute;
  right: 45px;
  top: 20px;
  cursor: pointer;

  &--small {
    right: 45px;
    top: 10px;
  }
}

.MuiSelect-select {
  padding-left: 24px !important;
}

.MuiPaper-root {
  background: var(--white);
  border: 1px solid #f1f1f1;
  box-shadow: 0 3px 9px 1px #12121229;
  padding: 5px 0px;
}

.MuiPopover-paper {
  border-radius: 12px !important;
}

.MuiMenuItem-root {
  font-weight: 400 !important;
  padding: 10px 20px !important;
  &:hover {
    color: #1e2125 !important;
    background-color: #f4f4f996 !important;
  }
  &:focus {
    background-color: #f4f4fa !important;
  }
}

.Mui-selected {
  background-color: #f4f4fa !important;
  color: var(--black) !important;
}

.MuiSelect-iconOutlined {
  margin-right: 2px;
}

.menuItem--small {
  font-size: 13px !important;
  padding: 8px 20px !important;
}

.dropdown-container--small label {
  font-size: 12px;
  margin-bottom: 4px;
}
