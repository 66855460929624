.full-screen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #191a1c;
  background-position: center;
  background-size: cover;
  z-index: 1300;

  .close-icon {
    top: 10px;
    right: 10px;
    position: absolute;
  }

  img {
    height: auto;
  }
}
