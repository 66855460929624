.location-search {
  margin-top: 35px;

  &--small {
    min-height: 44px !important;
    font-size: 13px;
  }
}

#location-search {
  padding: 0px 20px;
  border: 1px solid var(--light-purple);
  border-radius: 12px !important;
  background: #f4f4fa;
  font-weight: 100;
  height: 63px;

  &:focus {
    border: 1px solid var(--primary-orange);
  }

  &.white {
    background: var(--white);
  }
}

.pac-container {
  background: var(--white);
  border-radius: 12px;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  z-index: 999999;
  margin-top: 6px;
  overflow: scroll;
  max-height: 240px;
  box-shadow: 0 3px 9px 1px #00000036;
  padding: 5px 0px;
  min-width: 100px;

  .pac-item {
    padding: 4px 20px;
    border: none;
    font-size: 14px;
    color: rgb(139, 139, 148);

    &:hover {
      background-color: #f4f4f9 !important;
    }
  }

  &-query {
    font-size: 17px;
  }
}

.pac-icon {
  display: none;
}

.pac-logo:after {
  display: none;
}

.pac-container--small {
  .pac-item {
    font-size: 13px;
    padding: 4px 15px;
  }
}
