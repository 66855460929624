.view-more-modal {
  max-width: 612px;

  @media (min-width: 601px) {
    overflow-y: hidden;
  }
  @media (max-width: 600px) {
    height: 100vh;
    width: 100vw;
    padding: 36px !important;
  }

  .slots-by-day-container {
    height: 100%;
    width: 100%;
    justify-content: center;
    @media (min-width: 601px) {
      max-width: 190px;
    }
  }
}

.calendar-view {
  .MuiDateCalendar-root {
    overflow: visible;
    .MuiPickersCalendarHeader-label {
      font-weight: bold;
    }

    .MuiPickersCalendarHeader-root {
      padding-left: 0px;
      margin-top: 0px;
    }

    > .MuiPickersFadeTransitionGroup-root {
      font-weight: bold;
      border-radius: 16px;
      border: 1px solid var(--light-purple);
    }

    .MuiDayCalendar-weekDayLabel {
      font-weight: 600;
      color: var(--black);
      margin-top: 5px;
      font-size: 15px;
    }

    .MuiIconButton-root {
      background: #f4f4fb;
      border-radius: 2px;
      padding: 0px;
      height: fit-content;
      margin: 0px 1px;
    }

    .MuiSvgIcon-root {
      width: 19px;
      height: 19px;
    }

    .MuiPickersArrowSwitcher-spacer {
      width: 0px;
    }

    .MuiPickersDay-dayWithMargin {
      color: var(--black);
      transition: none;
      font-weight: 200;
      font-size: 13px;

      &:hover {
        background: #fff5ec;
        color: var(--primary-orange);
        font-weight: 300;
        border-radius: 11px;
      }
    }
    .Mui-selected {
      border-radius: 11px;
      border: 1px solid var(--primary-orange);
      background: #fff5ec;
      color: var(--primary-orange);
      font-weight: bold;
    }
    .MuiPickersDay-root {
      &:focus {
        border-radius: 11px;
        border: 1px solid var(--primary-orange);
        background: #fff5ec;
        color: var(--primary-orange);
        font-weight: bold;
      }
    }

    .MuiPickersDay-today {
      border-radius: 11px;
      background: #fff5ec;
      color: var(--primary-orange);
      font-weight: bold;
      border: none;
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .Mui-disabled {
      color: var(--light-purple);
    }
  }
}

.view-more-content {
  height: 494px;

  .slots-by-day {
    overflow-y: scroll;
  }
}
