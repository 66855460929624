.provider-card {
  max-width: 300px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--light-purple);
  background: var(--white);
  overflow-y: scroll;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    align-items: flex-start;
    padding: 0px !important;

    button {
      padding: 16px;
    }
  }

  @media (max-width: 1419px) {
    margin-bottom: 0px;
  }

  @media (max-width: 1243px) {
    max-width: none;
  }

  @media (max-width: 1010px) {
    padding: 16px;
    height: 100%;
  }

  &-details {
    margin: 0px 6px;

    p {
      font-size: 15px;
    }
  }

  h4 {
    display: -webkit-box;
    max-height: 250px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-slots {
    display: flex;
    margin-top: 12px;
    flex-direction: column;
  }
}
