.date-picker {
  font-size: 18px;
  color: var(--black);
  border-radius: 5px;

  &--grey {
    .MuiInputBase-root {
      height: 64px;
      border-radius: 12px;
      background: #f4f4f9;
      border: 1px solid var(--light-purple);
    }
  }

  &--white {
    .MuiInputBase-root {
      height: 64px;
      border-radius: 11px !important;
      border: 1px solid var(--light-purple);
      background: var(--white) !important;
      font-weight: 100;
    }
  }

  &-disabled {
    input {
      cursor: auto;
    }
    .MuiInputAdornment-root {
      opacity: 0;
    }
  }

  &__input-container {
    input {
      cursor: pointer;
      font-size: 17px;
      height: 64px;
      border-radius: 12px;
      background: #f4f4fa;
      padding: 24px;
      display: flex;
      position: relative;
      border: none;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }
  }

  &__header {
    background: var(--white);

    &:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      padding: 10px 0px;
    }
  }

  &__navigation {
    top: 9px;

    &-icon::before {
      border-color: #ccc;
      border-style: solid;
      border-width: 3px 3px 0 0;
      content: '';
      display: block;
      height: 6px;
      position: absolute;
      top: 6px;
      width: 6px;
    }
  }

  .MuiInputBase-input {
    font-size: 16px !important;
    font-weight: 100;
  }

  .MuiPickersDay-today {
    border-color: transparent !important;
    color: var(--primary-orange) !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid transparent !important;
    border-radius: 12px !important;
  }

  .Mui-focused {
    border: 1px solid var(--primary-orange) !important;
    border-radius: 12px !important;
    outline: none !important;
  }

  .MuiFormLabel-root {
    display: none !important;
  }

  .MuiPickersPopper-root {
    z-index: 99999 !important;
  }

  .MuiOutlinedInput-root {
    padding-right: 23px !important;
  }

  .MuiPickersDay-root {
    &:hover {
      background: #ff983826 !important;
    }
  }

  .MuiTextField-root {
    @media (max-width: 600px) {
      height: 52px;
    }
  }

  .MuiPickersDay-root.Mui-selected {
    color: white !important;
    background: var(--primary-orange) !important;
  }

  &--small {
    .MuiOutlinedInput-root {
      height: 44px;
    }
    .MuiInputBase-input {
      font-size: 13px !important;
    }
    svg {
      font-size: 20px;
    }
  }
}
