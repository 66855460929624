.provider-profile {
  .provider-info.desktop {
    display: flex;
  }

  .provider-info.mobile {
    display: none;
  }
  @media (max-width: 1050px) {
    flex-direction: column;

    .sidepanel {
      padding: 20px;
    }

    .provider-info.desktop {
      display: none;
    }
    .provider-title {
      align-items: center;
      justify-content: center;
    }

    .overflow-scroll {
      overflow: visible;
      height: fit-content;
    }

    .max-w-322 {
      max-width: 100%;
    }

    .provider-profile-main-panel {
      box-shadow: none;

      .services {
        padding: 30px 20px;
        border-top: 4px solid var(--extra-light-purple);
        border-bottom: 4px solid var(--extra-light-purple);
      }

      .provider-info.mobile {
        display: flex;
        padding: 30px 20px;
      }
    }
  }

  @media (max-width: 600px) {
    .provider-profile-main-panel {
      .children {
        padding: 0px;
      }
    }
  }
}
