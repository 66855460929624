.full-screen-loader {
  z-index: 50;
  position: absolute;
  background: var(--primary-blue);
  width: 100%;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;

  .progress-bar {
    background-color: var(--white);
    width: 160px;
    height: 4px;
    border-radius: 50px;
    overflow: hidden;
    display: block;
    position: relative;
    margin-top: 20px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--primary-orange);
      -webkit-animation: loadBar 2s cubic-bezier(0.09, 0.89, 0.7, 0.71) infinite;
      animation: loadBar 2s cubic-bezier(0.09, 0.89, 0.7, 0.71) infinite;
    }
  }
}

@keyframes loadBar {
  0% {
    left: -110%;
  }

  100% {
    left: 110%;
  }
}
