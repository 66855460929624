.payment-method-modal {
  .mobile-next-btn {
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0;
    padding: 16px;
    border-top: 1px solid var(--light-purple);
    background-color: var(--white);
  }
  .mobile-page-container {
    padding: 0px;
  }
}
