.slot-button {
  padding: 12px 16px;
  border-radius: 56px;
  cursor: pointer;
  background: var(--primary-orange);
  border: 1px solid var(--primary-orange);
  color: var(--white);
  font-size: 15px;
  font-weight: bold;
  min-width: 162px;
  margin: 10px 5px 0px 5px;

  &:hover {
    background: var(--secondary-orange);
    border-color: var(--secondary-orange);
  }

  &--mobile {
    padding: 10px 36px;
  }

  &--empty {
    background-color: var(--white);
    color: var(--primary-orange);
    padding: 11.5px 24px;
    margin-bottom: 0px;

    &:hover {
      background: var(--primary-orange);
      border: 1px solid var(--primary-orange);
      color: var(--white);
    }
  }
}
