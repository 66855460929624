.slots-by-day {
  @media (max-width: 550px) {
    width: 100%;
  }
  &-slots {
    @media (max-width: 550px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
      gap: 3px;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    .slot-button {
      margin-left: 0px;
      margin-right: 0px;
      width: 170px;
    }
  }
}
