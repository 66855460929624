.checkbox-with-actions {
  label {
    font-size: 18px;
    font-weight: 400;
    gap: 4px;
  }

  .checkbox-actions button {
    svg path,
    svg rect {
      fill: var(--primary-purple);
      transition: fill 0.2s ease;
    }

    &:hover svg path,
    &:hover svg rect {
      fill: var(--primary-blue);
    }
  }

  &:hover:not(:has(.checkbox-actions:hover)) {
    border: 1px solid var(--primary-orange);
    background-color: var(--extra-light-orange);
  }
}
