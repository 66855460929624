.consumer-landing-deep-link {
  background: var(--primary-green);
  padding-top: 36px;
  color: var(--white);
  width: 100%;

  &-nav {
    margin-bottom: 87px;
    padding: 0px 36px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;

      a {
        margin-top: 20px;
      }
    }
  }

  &-content {
    justify-content: center;

    @media (max-width: 570px) {
      flex-direction: column-reverse;
      text-align: center;
      padding-top: 80px;

      .actions {
        margin: 0 auto;
        padding: 20px 0px;
      }
      &__info {
        padding: 0px 20px !important;
      }
    }

    &__info {
      margin-top: -25px;
      padding-right: 30px;
      max-width: 547px;

      h1 {
        font-size: 36px;
        margin-bottom: 16px;
        line-height: 48px;
      }

      p {
        font-size: 20px;
        line-height: 32px;
      }
    }
    &__image {
      max-width: 280px;
      margin-right: 10px;
    }
  }
}
