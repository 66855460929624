.admin-navbar {
  .admin-nav-button {
    background: var(--primary-purple);
    border: none;
    color: var(--white);
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    cursor: default;
  }

  .admin-button {
    padding: 4px 5px 4px 16px;

    &--impersonating {
      background: var(--primary-green);
    }
  }

  .search-button {
    padding: 6px 11px 2px 11px;
    margin-left: 8px;

    &--impersonating {
      background: var(--primary-green);
    }
  }

  .impersonation-tooltip {
    position: absolute;
    background: #63637bf7;
    border-radius: 5px;
    margin-top: 67px;
    font-size: 14px;
    padding: 3px 9px;
    color: var(--white);
    font-weight: 200;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.242);
  }
}
