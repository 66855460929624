.add-ons,
.date-time,
.service-type,
.service-select,
.pet-select,
.pet-information,
.kennel-select,
.medications,
.behavior,
.vaccinations,
.groomer-select,
.user-information,
.payment {
  .mobile-next-btn {
    display: none;
    background-color: var(--white);
    border-top: 1px solid var(--light-purple);
    bottom: 0;
    left: 0;
    padding: 16px;
    position: fixed;
    width: 100%;
  }

  .mobile-next-btn button {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media (max-width: 910px) {
    flex-direction: column-reverse;
    height: 100%;
    justify-content: flex-end;

    .sidepanel {
      display: none;
      overflow: hidden;
    }

    .elevation-8 {
      overflow: hidden;
      box-shadow: none;
    }

    .children {
      height: 100%;
      overflow-y: auto;
      padding: 40px 20px;
    }

    .max-w-620 {
      padding: 40px 20px;
    }
    .min-w-386 {
      min-width: auto;
    }
    .max-w-620 {
      max-width: 100%;
      align-items: flex-start !important;
    }
  }
}
