.banner {
  background: #e1f1eb;
  padding: 16px;

  .icon-close {
    right: 40px;

    @media (max-width: 510px) {
      position: relative;
      right: inherit;
      margin-left: 10px;
    }
  }
}
