.default-avatar {
  background: #63637b;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;

  &--transparent {
    background: transparent;
  }

  &--extra-large {
    min-width: 130px;
    max-width: 130px;
    min-height: 130px;
    max-height: 130px;
    border-radius: 200px;
  }

  &--big {
    min-width: 96px;
    max-width: 96px;
    min-height: 96px;
    max-height: 96px;
  }

  &--medium {
    min-width: 54px;
    max-width: 54px;
    min-height: 54px;
    max-height: 54px;
  }

  &--small {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
  }

  &--extra-small {
    min-width: 23px;
    max-width: 23px;
    min-height: 23px;
    max-height: 23px;
  }
}
