.button {
  border: none;
  cursor: pointer;
  font-weight: bold;
  justify-content: center;
  transition: all 0.1s ease-out;

  &--square {
    border-radius: 12px;
  }

  &--round {
    border-radius: 100px !important;
  }

  &--default {
    background: var(--primary-orange);
    color: var(--white);

    &:hover {
      background: var(--secondary-orange);
    }
  }

  &--disabled {
    background: #ffd4a9;
    color: var(--white);
    pointer-events: none;

    .MuiCircularProgress-root {
      color: var(--white);
    }
  }

  &--reverse {
    background-color: transparent;
    color: var(--black);
    border: 1px solid #9191a8;

    &:hover {
      background-color: var(--primary-orange);
      color: var(--white);
      border: 1px solid var(--primary-orange);
    }
  }

  &--reverse-disabled {
    background-color: transparent;
    color: var(--secondary-purple);
    border: 1px solid #9191a8;
    pointer-events: none;

    .MuiCircularProgress-root {
      color: var(--secondary-purple);
    }
  }

  &--reverse-white {
    background: var(--white);
    color: var(--primary-orange);
  }

  &--reverse-white-green {
    background: var(--white);
    color: var(--primary-green);
  }

  &--reverse-orange {
    border: 1px solid var(--primary-orange);
    color: var(--primary-orange);
    background: none;

    &:hover {
      color: var(--white);
      background: var(--primary-orange);
    }
  }

  &--green {
    background-color: var(--primary-green);
    color: var(--white);
    border: 1px solid var(--primary-green);

    &:hover {
      background: var(--secondary-green);
      border: 1px solid var(--secondary-green);
    }
  }

  &--green-disabled {
    background-color: #8ccdbb;
    color: var(--white);
    border: #8ccdbb;
    pointer-events: none;

    .MuiCircularProgress-root {
      color: var(--white);
    }
  }

  &--transparent {
    background-color: transparent;
    color: var(--white);
    border: 1px solid var(--white);

    &:hover {
      background: var(--white);
      color: var(--black);
    }
  }

  &--white-outlined {
    background-color: var(--white);
    color: var(--black);
    border: 1px solid var(--light-purple);
  }

  &--white-outlined-disabled {
    background-color: var(--white);
    color: var(--light-purple);
    border: 1px solid var(--light-purple);
  }

  &--link {
    background-color: transparent;
    color: var(--primary-orange);
    font-weight: 400;
    border: 0;
    padding: 0 !important;

    &.button--medium {
      font-size: 16px;
    }

    &.button--small {
      font-size: 14px;
    }
  }

  &--link-disabled {
    background-color: transparent;
    color: var(--secondary-purple);
    pointer-events: none;
    font-weight: 400;
    border: 0;
    padding: 0;
  }

  &--light-gray {
    background: #f4f4fa;
    color: var(--primary-purple);
  }

  &--large {
    font-size: 18px;
    padding: 16px 36px;
  }
  &--medium {
    font-size: 15px;
    padding: 12px 32px;
  }
  &--small {
    font-size: 12px;
    padding: 12px 24px;
  }
  &--extra-small {
    font-size: 12px;
    padding: 8px 16px;
    border-radius: 6px;
  }

  .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
    margin-left: 10px;
  }
}
