.terms-service {
  &-container {
    max-width: 1000px;
    padding-bottom: 50px;
    li {
      font-weight: 100;
      &::marker {
        font-weight: bold;
      }
    }
  }
}
